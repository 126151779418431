.c-loader{
  width:100%;
  height:100%;
  position:fixed;
  top:0;
  left:0;
  display:flex;
  align-items:center;
  justify-content: center;
  z-index:999;
  background:rgba(255,255,255,0.8);
}
.customBtn{
  display: inline-block;
  vertical-align: middle;
  padding: 6px 15px;
  border-radius: 5px;
  font-size: 12px;
  color: #fff;
}
.c-banner-management .css-honmcm-MuiGrid-root{
  width:100%;
  margin-left:0;
}
.c-banner-management .css-honmcm-MuiGrid-root>.MuiGrid-item{
  padding-left:0;
}
.c-add-banner-modal .css-1em9ut6-MuiGrid-root{
  margin-bottom:15px;
}
.c-add-banner-modal .MuiFormControl-root{
  width:100%;
}
.c-add-banner-modal .MuiInputLabel-formControl{
  position:static;
  transform:none;
  margin:0 0 6px;
}
.c-add-banner-modal label+.css-m1hy3n-MuiInputBase-root-MuiInput-root{
  margin-top:0;
}
.c-add-banner-modal .css-1tuqtw1-MuiButtonBase-root-MuiButton-root, .customBtn{
  background-color:#1A73E8 !important;
  color: #FFF !important;
}
.c-add-banner-modal #my-input{
  margin-bottom:5px;
}
.show{
  display: block;
}
.hide{
  display: none;
}
.c-treatment-modal .MuiBox-root{
  width:100%;
  max-width:800px;
}
.c-treatment-table table tbody tr td:nth-child(2) .css-ekdnj9, 
.c-treatment-table table tbody tr td:nth-child(3) .css-ekdnj9{
  width: auto !important;
}
.c-add-banner-modal .css-1wnsr1i{
  width: 100%;
  max-width: 680px;
  height: 90vh;
  overflow-y: auto;
}
.c-add-banner-modal .MuiBackdrop-root{
  pointer-events:none;
}
.cancelBtn{
  margin-left:10px !important;
}
.react-datepicker__input-container input{
  width:100%;
  height:40px;
  border:none;
  border-bottom:#ccc 1px solid;
  outline:none !important;
}
.react-datepicker-popper{
  z-index:9;
}
.c-menu a:first-child{
  display:none;
}

.PhoneInputInput{
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0.25rem;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}
.PhoneInputInput:focus{
  outline:none;
}

.c-add-banner-modal .MuiFormControl-root {
  margin-bottom: 22px !important;
}
.c-add-banner-modal .MuiFormControl-root  .MuiInput-root{
  margin-top:0 !important;
}